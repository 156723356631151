<template>
  <span>
    <ul
      v-if="timer > 0"
      class="vuejs-countdown"
    >
      <li>
        <p class="digit">
          {{ days | twoDigits }}
        </p>
        <p class="text">
          {{ days > 1 ? 'dias' : 'dia' }}
        </p>
      </li>
      <li>
        <p class="digit">
          {{ hours | twoDigits }}
        </p>
        <p class="text">
          {{ hours > 1 ? 'horas' : 'hora' }}
        </p>
      </li>
      <li>
        <p class="digit">
          {{ minutes | twoDigits }}
        </p>
        <p class="text">
          min
        </p>
      </li>
      <li>
        <p class="digit">
          {{ seconds | twoDigits }}
        </p>
        <p class="text">
          Seg
        </p>
      </li>
    </ul>

    <div
      v-if="timer < 1"
      style="font-size: 24px; font-weight: 600; line-height: 1.5;"
    >
      Sorteando{{ pointsLoading }}
    </div>
  </span>
</template>

<script>
import moment from 'moment'
import 'moment-duration-format'

export default {
  filters: {
    twoDigits (value) {
      if (value.toString().length <= 1) {
        return `0${value.toString()}`
      }
      return value.toString()
    }
  },
  props: {
    date: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    pointsLoading: '.',
    loading: true,
    timer: 0
  }),
  computed: {
    days () {
      return moment.duration(this.timer).days()
    },
    hours () {
      return moment.duration(this.timer).hours()
    },
    minutes () {
      return moment.duration(this.timer).minutes()
    },
    seconds () {
      return moment.duration(this.timer).seconds()
    }
  },
  watch: {
    date (val) {
      console.log(val)
    },
    timer (val) {
      this.$emit('timer', val)
    }
  },
  mounted () {
    const timerDiff = moment(
      this.date
    ).diff()

    const utcOffset = (60 * 60 * 3 * 1000)

    this.timer = timerDiff - utcOffset

    setInterval(() => {
      if (this.timer > 0) {
        this.timer -= 1000
      } else {
        this.timer = 0
        this.pointsLoading.split('.').length === 4
          ? this.pointsLoading = '.'
          : this.pointsLoading += '.'
      }
    }, 1000)
  }
}
</script>

<style>
.vuejs-countdown {
  padding: 0;
  margin: 0;
}
.vuejs-countdown li {
  display: inline-block;
  margin: 0 8px;
  text-align: center;
  position: relative;
}
.vuejs-countdown li p {
    margin: 0;
}
.vuejs-countdown li:after {
  content: ":";
  position: absolute;
  top: 0px;
  right: -13px;
  font-size: 32px;
}
.vuejs-countdown li:first-of-type {
  margin-left: 0;
}
.vuejs-countdown li:last-of-type {
  margin-right: 0;
}
.vuejs-countdown li:last-of-type:after {
  content: "";
}
.vuejs-countdown .digit {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
}
.vuejs-countdown .text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 10px;
}
</style>
