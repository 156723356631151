<template>
  <section>
    <b-tabs
      content-class="mt-1"
      align="center"
    >
      <b-tab title="Histórico de Sorteios">
        <history />
      </b-tab>

      <b-tab title="Meus Números da Sorte">
        <MyNumbers />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import History from './History.vue'
import MyNumbers from './MyNumbers.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    History,
    MyNumbers
  }
}
</script>
