<template>
  <b-card>
    <TablePagination
      v-model="items"
      url="/user/draws/history"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:title="data">
        {{ data.items.title }}
      </template>

      <template v-slot:number_winner="data">
        {{ data.items.winner.number_winner }}
      </template>

      <template v-slot:date="data">
        {{ data.items.end | date }}
      </template>

      <template v-slot:full_name="data">
        {{ data.items.winner.full_name }}
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'

export default {
  components: {
    BCard,
    TablePagination
  },
  filters: {
    date (val) {
      return moment(val).utcOffset('-300').format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    fields: [
      {
        key: 'title',
        label: 'Nome'
      },
      {
        key: 'number_winner',
        label: 'Número sorteado'
      },
      {
        key: 'date',
        label: 'Data do sorteio'
      },
      {
        key: 'full_name',
        label: 'Ganhador'
      }
    ],
    items: []
  })
}
</script>
