<template>
  <div>
    <ComingSoon v-if="show && Object.keys(draws).length === 0" />

    <section v-if="show && Object.keys(draws).length > 0">
      <b-card>
        <b-card-title
          class="d-flex justify-content-center text-center text-primary mb-2"
          style="font-size: 24px"
        >
          {{ draws.title }}
        </b-card-title>

        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-center mb-2"
          >
            <coutdown
              v-if="draws.finished === 0"
              :date="draws.end"
              @timer="timer = $event"
            />
            <div
              v-else
              class="text-center"
              style="font-size: 16px; font-weight: 600; line-height: 1.5;"
            >
              <span class="mb-1">Sorteio terminado em {{ draws.end | date }}</span> <br>
              <span>
                O <b class="text-primary">Ganhador</b> foi <b class="text-success">{{ draws.winner.full_name }}</b> de <b class="text-success">ID: {{ draws.winner.id }}</b> com o número <b class="text-success">{{ draws.winner.number }} sorteado.</b></span>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-center text-center mb-1"
          >
            <span v-html="draws.description" />
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-center text-center mb-1"
          >
            <div>
              <span class="w-100">Valor do número: </span> <br>
              <span class="font-weight-bolder text-success">
                {{ draws.price_per_number | currency }}
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-center text-center mb-1"
          >
            <div>
              <span class="w-100">Você tem <span class="font-weight-bolder text-success">
                {{ draws.free_numbers }}
              </span> número<span v-if="draws.free_numbers > 0">s</span> gratuito<span v-if="draws.free_numbers > 0">s</span> disponível</span> <br>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-center text-center"
          >
            <b-form-group
              label="Digite a quantidade de números"
              label-for="numbers"
            >
              <b-form-input
                v-if="
                  (draws.price_per_number === 0.0 && draws.free_numbers === 0) || timer < 1
                "
                id="numbers"
                v-model="numbers"
                type="number"
                min="1"
                max="1"
                class="text-center"
                :disabled="
                  (draws.price_per_number === 0.0 && draws.free_numbers === 0) || timer < 1
                "
              />
              <cleave
                v-else
                id="number"
                v-model="numbers"
                class="form-control text-center"
                :raw="true"
                :options="options.number"
                placeholder="10,000"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-center text-center"
          >
            <b-button
              :disabled="loading || (draws.price_per_number === 0.0 && draws.free_numbers === 0) || timer < 1"
              variant="relief-primary"
              @click.stop="purchaseDrawDialog()"
            >
              <span v-if="!loading">Comprar meu número da sorte</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <Tabs v-if="!loading" />
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
  BButton
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import coutdown from './Coutdown.vue'
import ComingSoon from './ComingSoon.vue'
import Tabs from './Tabs.vue'
import currencyPrecision from '@/libs/currencyPrecision'
import SwalMessages from '../BuyServices/utils/SwalMessages'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    Cleave,
    coutdown,
    ComingSoon,
    Tabs
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).utcOffset('-300').format('DD/MM/Y [às] HH:mm')
    }
  },
  data: () => ({
    show: false,
    loading: false,
    timer: 0,
    timerInterval: null,
    numbers: 1,
    options: {
      number: {
        numeral: true,
        numeralDecimalScale: 0
      }
    },
    draws: []
  }),
  async mounted () {
    await this.getDraws()
  },
  methods: {
    async getDraws () {
      this.show === true ? null : this.show = false
      const url = '/user/draws'

      await this.$http
        .get(url)
        .then(res => {
          this.draws = res.data
        })
        .finally(() => {
          this.show = true
          this.loading = false

          clearInterval(this.timerInterval)
          this.timerInterval = setInterval(() => {
            if (this.show === true && this.loading === false && this.timer < 1 && this.draws.finished === 0) {
              this.getDraws()
              this.loading = true
            } else if (this.show === true && this.loading === false && this.timer < 1 && this.draws.finished === 0) {
              clearInterval(this.timerInterval)
            }
          }, 10000)
        })
    },
    purchaseDrawDialog () {
      this.$swal({
        title: `Você tem certeza que deseja comprar ${this.numbers} ${this.numbers > 1 ? 'números' : 'número'} da sorte?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, comprar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.purchaseDraw()
        }
      })
    },
    async purchaseDraw () {
      this.loading = true

      const url = `/user/draws/purchase/${this.numbers}`

      await this.$http
        .post(url)
        .then(res => {
          res.data.message === 'Draw numbers purchased successfully.' ? this.swalSuccess() : ''
          res.data.message === 'no balance.' ? this.swalNoBalance() : ''
          res.data.message === 'You already have a free number for this draw.' ? this.swalUserHasDrawNumber() : ''
        })
        .finally(() => {
          this.loading = false
        })
    },
    swalSuccess () {
      this.show = false
      this.getDraws()

      setTimeout(() => {
        this.$swal({
          title: 'Compra realizada com sucesso!',
          text: 'Obrigado por sua compra!',
          icon: 'success',
          confirmButtonText: 'Fechar',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }, 100)
    },
    swalUserHasDrawNumber () {
      this.$swal({
        title: 'Você não tem a quantidade específicada de números gratuitos para este sorteio.',
        text: `Atualmente você tem apenas ${this.draws.free_numbers} números gratuitos para este sorteio.`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    swalNoBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
